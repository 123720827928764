import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import './Question.css'
import { format } from 'date-fns';

const QuestionLeft = ({ question, questionLeftArray }) => {
  // Get the latest responses directly from the question
  if (Array.isArray(questionLeftArray)) {
    const latestResponses = questionLeftArray;

    // Ensure latestResponses is an array
    // if (!Array.isArray(latestResponses)) {
    //   console.error('latestResponses is not an array:', latestResponses);
    //   return null;
    // }
    return (
      <div>
        <h4>{question.responses[0].user.name} {format(new Date(question.responses[0].createdAt), 'yyyy-MM-dd HH:mm:ss')}</h4>
        {latestResponses.map((response, index) => (
          <Row key={response.response_id} className="mb-2 align-items-center">
            <Col xs={4}><h4 className="response-text">{question.emotions[index]}</h4></Col>
            <Col xs={8}>
              <ProgressBar 
                now={response} 
                label={`${response}%`} 
                className="small-progress" 
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  } else { 
    const latestResponses = question.responses || [];

    // Ensure latestResponses is an array
    if (!Array.isArray(latestResponses)) {
      console.error('latestResponses is not an array:', latestResponses);
      return null;
    }
    return (
      <div>
        <h4>{question.responses[0].user.name} {format(new Date(question.responses[0].createdAt), 'yyyy-MM-dd HH:mm:ss')}</h4>
        {latestResponses.map((response, index) => (
          <Row key={response.response_id} className="mb-2 align-items-center">
            <Col xs={4}><h4 className="response-text">{question.emotions[index]}</h4></Col>
            <Col xs={8}>
              <ProgressBar 
                now={response.value} 
                label={`${response.value}%`} 
                className="small-progress" 
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  };
  }
  

export default QuestionLeft;
