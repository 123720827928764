// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.result-admin-survey-main{

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);

    min-height: 92vh;
    margin-top: 40px;
    margin-bottom: 20px;

    color: #333;
}



.result-admin-survey-top{
    width: 99%;

    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
    overflow-x: auto;
    border-radius: 10px;
    padding-top: 50px;
}


.result-admin-survey-ser{
    margin-top: 10px;
    width: 98%;
    /*border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);*/
    margin-bottom: 10px;

}

.result-admin-survey-main h3 {
    color: #333; 
    background-color: #f1f1f1; 
    padding: 10px; 
    border-radius: 5px; 
    text-align: center; 
    width: 99%;
}`, "",{"version":3,"sources":["webpack://./src/mySurvey/resultsSurveAdmin.css"],"names":[],"mappings":";AACA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,oCAAoC;IACpC,mBAAmB;IACnB,iDAAiD;;IAEjD,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;;IAEnB,WAAW;AACf;;;;AAIA;IACI,UAAU;;IAEV,+CAA+C;IAC/C,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;;AAGA;IACI,gBAAgB;IAChB,UAAU;IACV;qDACiD;IACjD,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":["\n.result-admin-survey-main{\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    background-color: rgb(255, 255, 255);\n    border-radius: 15px;\n    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);\n\n    min-height: 92vh;\n    margin-top: 40px;\n    margin-bottom: 20px;\n\n    color: #333;\n}\n\n\n\n.result-admin-survey-top{\n    width: 99%;\n\n    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);\n    overflow-x: auto;\n    border-radius: 10px;\n    padding-top: 50px;\n}\n\n\n.result-admin-survey-ser{\n    margin-top: 10px;\n    width: 98%;\n    /*border-radius: 10px;\n    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);*/\n    margin-bottom: 10px;\n\n}\n\n.result-admin-survey-main h3 {\n    color: #333; \n    background-color: #f1f1f1; \n    padding: 10px; \n    border-radius: 5px; \n    text-align: center; \n    width: 99%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
