// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* for progress bars and emotions*/
.small-progress {
    background-color: #e0e0e0; /* Колір фону прогрес-бару */
}
  
.small-progress .progress-bar {
    background-color: #027BFF; /* Колір заповнення прогрес-бару */
}

.response-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    /*flex-grow: 1;*/
}

.custom-progress-bar .progress-bar {
    background-color: pink !important; /* Зміни колір на рожевий */
  }`, "",{"version":3,"sources":["webpack://./src/mySurvey/Question.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,yBAAyB,EAAE,4BAA4B;AAC3D;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;AACjE;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,iCAAiC,EAAE,2BAA2B;EAChE","sourcesContent":["/* for progress bars and emotions*/\n.small-progress {\n    background-color: #e0e0e0; /* Колір фону прогрес-бару */\n}\n  \n.small-progress .progress-bar {\n    background-color: #027BFF; /* Колір заповнення прогрес-бару */\n}\n\n.response-text {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between; \n    /*flex-grow: 1;*/\n}\n\n.custom-progress-bar .progress-bar {\n    background-color: pink !important; /* Зміни колір на рожевий */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
