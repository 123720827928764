import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverUrl } from '../../Utils/storage';
import '../../Auth/LoginPage.css'

function GoogleAuthButton() {
  let navigate = useNavigate();

  const handleLogin = () => {
    // Redirect to your NestJS backend auth route
    window.location.href = serverUrl + '/auth/google/callback';
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      // Store the token in local storage
      localStorage.setItem('authToken', token);
      // Use navigate to redirect to the logged-in page
      navigate('/logged-in');
    }
  }, [navigate]);

  return (
    <button onClick={handleLogin} className="login-register-swbtn">Login with Google</button>
  );
}

export default GoogleAuthButton;
