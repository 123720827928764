import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './Auth/loginPage';
import LoggedInPage from './Auth/loggedInPage';
import { SurveyCreationPage } from './createSurvey/createSyrvey';
import StoreToken from './Auth/StoreToken';
import TakeSurveyPage from './takeSurvey/takeSurvey';
import SurveyPage from './takeSurvey/surveyPage';
import HashPage from './createSurvey/HashPage';
import MySurvey from './mySurvey/mySurvey';
import EditSurvey from './mySurvey/editSurvey';
import SurveyResults from './mySurvey/resultsSurvey';
import ForgotPasswordPage from './Auth/ForgotPasswordPage';
import SurveyPassedUsersAdmin from './mySurvey/SurveyPassedUsersAdmin';
import SurveyResultsAdmin from './mySurvey/resultsSurveAdmin';
import AdminKolodniy from './mySurvey/AdminKolodniy';


function App() {
  // Check if the authentication token exists in local storage
  const authToken = localStorage.getItem('authToken');

  return (
    <Router>
      <script src="http://localhost:8097"></script>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={authToken ? <Navigate replace to="/logged-in" /> : <Navigate replace to="/login" />} />
            <Route path="/logged-in" element={<LoggedInPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/survey/:surveyHash" element={<SurveyPage />} />
            <Route path="/storeToken" element={<StoreToken />} />
            <Route path="/take-survey" element={<TakeSurveyPage />} />
            <Route path="/create-survey" element={<SurveyCreationPage />} />
            <Route path="/create-survey/success" element={<HashPage />} />
            <Route path="/my-surveys" element={<MySurvey />} />
            <Route path="/edit-survey/:id" element={<EditSurvey />} />
            <Route path="/survey-results/:id" element={<SurveyResults />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/survey-results-admin/:survey_id" element={<SurveyPassedUsersAdmin />} />
            <Route path="/results-admin/:id/:user_id" element={<SurveyResultsAdmin />} />
            <Route path="/results-kolodniy/:id/:user_id" element={<AdminKolodniy />} />
          
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
