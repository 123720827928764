import React from 'react';
import QuestionForm from './QuestionForm'; // Import the QuestionForm component

const QuestionList = ({ questions, setQuestions }) => {
  const handleQuestionChange = (index, newQuestion) => {
    const updatedQuestions = questions.map((question, i) =>
      i === index ? newQuestion : question
    );
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    // Create a new array without the question at the given index
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

return (
    <div className="question-list">
      {questions.map((question, index) => (
        <QuestionForm
          key={index}
          question={question}
          setQuestion={(newQuestion) => handleQuestionChange(index, newQuestion)}
          index={index}
          handleRemoveQuestion={handleRemoveQuestion}
        />
      ))}
    </div>
  );
};

export default QuestionList;
