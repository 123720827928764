import React, { useState } from 'react';
import GoogleAuthButton from '../Components/Buttons/GoogleAuthButton';
import './LoginPage.css'; // Make sure to import the CSS file
import axios from 'axios';
import { serverUrl } from '../Utils/storage';
import { handlePictureChange, validateEmail, validatePassword } from './authFunctions';
import { useNavigate } from 'react-router-dom';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'


function LoginPage() {
  const [isLogin, setIsLogin] = useState(true); // State to toggle between login and create account
  const [name, setName] = useState(''); // State for name input
  const [email, setEmail] = useState(''); // State for email input
  const [password, setPassword] = useState(''); // State for password input
  // const [picturePreviewUrl, setPicturePreviewUrl] = useState(''); // State for previewing the selected picture
  // const [pictureFile, setPictureFile] = useState(null); // State for holding the selected picture file
  const [message, setMessage] = useState(''); // State for displaying messages to the user

  let navigate = useNavigate();

  const toggleMode = () => {
    setIsLogin(!isLogin); // Toggle between login and registration
    setEmail('youremail@gmail.com'); // Clear the email field
    setPassword(''); // Clear the password field
  };

  const ForgotPassword = () => {
    //alert("Forgot password!");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(serverUrl + '/auth/login', {
        email: email,
        password: password
      });
  
      if (response.data.success) {
        // const user = response.data.user;
  
        const token = response.data.accessToken;
        if (!token || token === '' || token === undefined) {
          console.error('Login failed: No token received');
          return;
        }
        localStorage.setItem('authToken', token);
  
        console.log('Login successful');
  
        navigate('/logged-in'); // Redirect to the logged-in page
      } else {
        // Handle the case where the login was not successful
        setMessage('Login failed. Please try again.');
        // console.error('Login failed: User not found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


const handleSignUp = async (e) => {
  e.preventDefault();
  console.log(e);
  if (!validateEmail(email)) {
    setMessage('Please enter a valid email address.');
    console.log('Invalid email');
    return;
  }

  if (!validatePassword(password)) {
    setMessage('Password must be at least 8 characters long.');
    console.log('Invalid password');
    return;
  }

  try {
    const response = await axios.post(serverUrl + '/auth/signup', {
      name: name,
      email: email,
      password: password,
      picture: 0
    });
    const resp = response.data;
    if (!resp.success) {
      console.error('Sign up failed');
      if (resp.code === 409) {
        // User already exists
        // Switch to the login form and inform the user
        setIsLogin(true);
        setEmail('youremail@gmail.com'); // Clear the email field
        setPassword(''); // Clear the password field
        setMessage('User already exists. Please log in.');
      } else {
        setMessage('Sign up failed. Please try again.');
      }
    } else {
      setIsLogin(true);
      setMessage('Sign up successful. You can now log in.');
    }
  } catch (error) {
    // Handle any errors
    console.error('Error:', error);
  }
};

const handleForgotPassword = () => {
  navigate('/forgot-password'); // Redirect to the forgot password page
};


return (

  <div className="LoginPage">

    <TopPanelBase/>

    {message && <p>{message}</p>}
    <div className="login-container">
    <form onSubmit={isLogin ? handleLogin : handleSignUp} className="login-form">
    <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>

      {!isLogin && (
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          required
        />
      )}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />

          {isLogin && (
            <div className="forgot-password-container">
              <button type="button" onClick={handleForgotPassword} className='login-forgot-password'>
                Forgot password?
              </button>
            </div>
          )}


      {/* Add the file input for selecting a picture */}
      {/* {!isLogin && (
        <div>
          <label htmlFor="picture">Select Picture:</label>
          <input
            type="file"
            id="picture"
            accept="image/*"
            onChange={(e) => handlePictureChange(e.target.files[0])}
            required
          />
        </div>
      )} */}


      <button type="submit" className="login-submitbtn">{isLogin ? 'Login' : 'Create Account'}</button>
    </form>
    </div>
    
    <button onClick={toggleMode} className="login-register-swbtn">
      {isLogin ? 'Need an account? Sign up.' : 'Already have an account? Login.'}
    </button>

    <p className='login-google-text'>Or login with Google:</p>
    <GoogleAuthButton/>

    <BotPanel is_fixed={true}/>
  </div>
);
}

export default LoginPage;
