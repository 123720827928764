import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { serverUrl } from '../Utils/storage';
import Question from './Question';
/*import QuestionLeft from './QuestionLeft';
import QuestionRight from './QuestionRight';*/
import ChartComponent from './ChartComponent';
import { generateColors } from './ChartComponent';

import './resultsSurvey.css'
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

const SurveyResults = () => {
  document.title = "Poll Results";
  
  const { id } = useParams(); // Отримуємо id опитування з URL
  const [data, setData] = useState(null);
  const [similar, setSimilar] = useState(null);
  // const [soulmate, setSoul] = useState(null);



  useEffect(() => {
    // Отримуємо дані результатів при завантаженні компонента
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${serverUrl}/survey/${id}/results`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching survey results:', error);
      }
    };

    // const SoulMate = async () => {
    //   try {
    //     const response = await axios.get(`${serverUrl}/survey/${id}/results`, {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
    //       },
    //     });
    //     setSoul(response.data);
    //   } catch (error) {
    //     console.error('Error fetching survey results:', error);
    //   }
    // };

    const fetchSimilar = async () => {
        try {
          const response = await axios.get(`${serverUrl}/survey/${id}/all-results`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
            },
          });
          setSimilar(response.data);
        } catch (error) {
          console.error('Error fetching survey results:', error);
        }
      };

      fetchSimilar();
      // SoulMate()
      fetchResults();
    /*const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };*/
  }, [id]);

  if (!data || !similar) {
    return <div>Loading results...</div>;
  }

  return (
    <div className='base-unfixed-footer'>
    <div className='base-unfixed-footer-content'>
    <div className="result-survey-main">
      <TopPanelBase/> 
      <div className='result-survey-top'>
        <h1>Poll Results</h1>
        <h2 style={{fontSize: '30px'}}>{data.survey.title}</h2>
        <p>{data.survey.description}</p>
      </div>

      <h3>Questions</h3>

      <div className='result-survey-ser'>
      {data.questions.map((question, index) => {
        
        let responseUser = [];
        question.responses.forEach((resp) => {
          responseUser.push(resp.value);
        });

        const responsesAll = similar['avgValue'][question.questionId];/*similar.questions[index].responses;*/
        const colors = generateColors(question.emotions);

        return (
          <div key={question.questionId} className='result-survey-ser-unit'>
                <Question question={question}/>
                <p>Your response:</p>
                <ChartComponent responses={responseUser} emotions={question.emotions} colors={colors}/>

                <p>Collective response: </p>
                <ChartComponent responses={responsesAll} emotions={question.emotions} colors={colors}/>
          </div>
        );
      })}
      </div>


    </div>
    </div>
      <BotPanel is_fixed={false}/>
    </div>
  );
};
export default SurveyResults;
