import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import './Question.css'
import { format } from 'date-fns';

const QuestionLeftSoulMate = ({ question, questionSoulMate }) => {
  const latestResponses = question.responses || [];
  const soulMateResponses = questionSoulMate ? questionSoulMate.responses : [];
  let DifferenceMetric = null


  if (!Array.isArray(latestResponses)) {
    console.error('latestResponses or soulMateResponses is not an array:', { latestResponses, soulMateResponses });
    return null;
  }
  const differences = latestResponses.map((response, index) => {
    let soulMateValue;
    if (Array.isArray(questionSoulMate)) {
      soulMateValue = questionSoulMate[index];
    } else {
      const soulMateResponseMap = soulMateResponses.map(response => response.value);
      soulMateValue = soulMateResponseMap[index];
    }
    return soulMateValue !== undefined ? response.value - soulMateValue : null;
  }).filter(difference => difference !== null);

  // Step 2: Calculate the sum of the absolute differences
  const sumOfAbsoluteDifferences = differences.reduce((acc, difference) => acc + Math.abs(difference), 0);

  // Step 3: Calculate the average (DifferenceMetric)
  const differenceMetric = sumOfAbsoluteDifferences / differences.length;


  return (
    <div>
      {Array.isArray(questionSoulMate) && (
      <h4>
        {question.responses[0].user.name}{' '}
        {format(new Date(question.responses[0].createdAt), 'yyyy-MM-dd HH:mm:ss')}
      </h4>
      )}

      {!Array.isArray(questionSoulMate) && (
        <h4>
          {soulMateResponses[0].user.name}{' '}
          {format(new Date(soulMateResponses[0].createdAt), 'yyyy-MM-dd HH:mm:ss')}
        </h4>
      )}
      {latestResponses.map((response, index) => {
        let soulMateValue; 
        if (Array.isArray(questionSoulMate)) {
          soulMateValue = questionSoulMate[index]
        } else {
          console.log(soulMateResponses)
          const soulMateResponseMap = soulMateResponses.map(response => response.value);
          soulMateValue = soulMateResponseMap[index];
          console.log('u v ', response.value, 'sm v', soulMateValue, index)

        }
        const difference = soulMateValue !== undefined ? response.value - soulMateValue : null;

        return (
        <Row key={response.response_id} className="mb-2 align-items-center">
          <Col xs={4}>
            <h4 className="response-text">{question.emotions[index]}</h4>
          </Col>
          <Col xs={8}>
            <div className="progress position-relative" style={{ height: '20px' }}>
              {difference !== null && (
                <>
                  {difference < 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '50%',
                        width: `${Math.abs(difference/2)}%`,
                        transform: 'translateX(-100%)',
                        backgroundColor: 'red',
                        height: '100%',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          right: '-10%',
                          transform: 'translateX(100%)',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                      >
                        {difference.toFixed(2)}
                      </span>
                    </div>
                  )}
                  {difference > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '50%',
                        width: `${Math.abs(difference/2)}%`,
                        backgroundColor: 'green',
                        height: '100%',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          left: '-10%',
                          transform: 'translateX(-100%)',
                          color: 'black',
                          fontSize: '1.2em',
                        }}
                      >
                        {`+${difference.toFixed(2)}`}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
         
        );
       
      })}
      <h2>DifferenceMetric: {differenceMetric.toFixed(2)}</h2>
    </div>
  );
};

export default QuestionLeftSoulMate;



