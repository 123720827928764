import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './takeSurvey.css';

import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

export function TakeSurveyPage() {
  const [surveyHash, setSurveyId] = useState('');
  let navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Логіка для перевірки ідентифікатора опитування
    // Наприклад, можна зробити запит до API для отримання опитування
    // А потім перенаправити користувача на сторінку з опитуванням

    // Припустимо, що є маршрут '/survey/:id' для відображення опитування
    navigate(`/survey/${surveyHash}`);
  };

  return (
    <div className="take-survey-page">
      <TopPanelBase/>
      <h1 htmlFor="surveyId">Код опитування:</h1>
      <form onSubmit={handleSubmit}>

        <input
          id="surveyId"
          type="text"
          value={surveyHash}
          onChange={(e) => setSurveyId(e.target.value)}
          placeholder="Введіть унікальний ідентифікатор опитування"
        />
        <button type="submit">Пройти</button>
      </form>
      <p></p>

      <BotPanel is_fixed={true}/>
    </div>
  );
}

export default TakeSurveyPage;
