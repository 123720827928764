// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.take-survey-page {
    width: 400px;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    color: #333;
  }
  
  .take-survey-page form {
    display: flex;
    flex-direction: column;
  }

  .take-survey-page h1 {
    font-size: 35px;
  }
  
  .take-survey-page label {
    margin-bottom: 5px;
  }
  
  .take-survey-page input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .take-survey-page button {
    padding: 10px;
    background-color: #b115b7;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .take-survey-page button:hover {
    background-color: #9920a6;
  }

  .take-survey-page button:active {
    background-color: #860a94;
    box-shadow: 0 5px #720a7d;
    transform: translateY(2px);
  }
`, "",{"version":3,"sources":["webpack://./src/takeSurvey/takeSurvey.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,0BAA0B;EAC5B","sourcesContent":[".take-survey-page {\n    width: 400px;\n    max-width: 400px;\n    margin: auto;\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);\n    color: #333;\n  }\n  \n  .take-survey-page form {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .take-survey-page h1 {\n    font-size: 35px;\n  }\n  \n  .take-survey-page label {\n    margin-bottom: 5px;\n  }\n  \n  .take-survey-page input {\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .take-survey-page button {\n    padding: 10px;\n    background-color: #b115b7;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .take-survey-page button:hover {\n    background-color: #9920a6;\n  }\n\n  .take-survey-page button:active {\n    background-color: #860a94;\n    box-shadow: 0 5px #720a7d;\n    transform: translateY(2px);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
