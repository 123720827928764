// ChartComponent.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

export const generateColors = (emotions) => {
    return emotions.map(() => getRandomColor());
  };

const prepareChartData = (responses, emotions, colors) => {
  const emotionValues = {};
  emotions.forEach(emotion => emotionValues[emotion] = []);

  emotions.forEach((emotion, index) => {
    const roundedValue = Math.round(responses[index] * 10) / 10;
    if (roundedValue < 1) {
      emotionValues[emotion].push(1);
    }
    emotionValues[emotion].push(roundedValue);
    });

  const datasets = emotions.map((emotion, index) => ({
    label: emotion,
    data: emotionValues[emotion],
    backgroundColor: colors[index],
  }));
  return {
    labels: [''],
    datasets,
  };
};

const ChartComponent = ({ responses, emotions, colors }) => {
  const chartData = prepareChartData(responses, emotions, colors);
  const options = {
    scales: {
      y: { // y-axis for vertical bars
        beginAtZero: true, // Ensure the chart starts from 0
        min: 0, // Explicitly set min value to 0
        max: 100, // Set the max value of y-axis to 100
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default ChartComponent;
