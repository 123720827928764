// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hash-page {
    width: 500px;
    max-width: 500px;
    margin: auto;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    color: #333;

    min-height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hash-page-main {
    width: 99%;
    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
    border-radius: 10px;
    overflow-x: auto;
}


.hash-page_main_text{
    color: #333; 
    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);
    background-color: #f1f1f1;; 
    padding: 10px; 
    border-radius: 5px; 
    text-align: center; 
    width: 95%;
}


.base-button{
    display: flex;
    gap: 10px;
    justify-content: center; 
    align-items: center;
    margin-top: -20px;
    padding-bottom: -20px;
    /*box-shadow: 0px 0px 20px rgba(0,0,0,1);*/
}

.hash-page-unit{
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(126, 2, 103, 0.15), rgba(255, 255, 255, 1));
}`, "",{"version":3,"sources":["webpack://./src/createSurvey/HashPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;;IAEX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,+CAA+C;IAC/C,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;IACI,WAAW;IACX,+CAA+C;IAC/C,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;AACd;;;AAGA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,0CAA0C;AAC9C;;AAEA;IACI,8GAA8G;AAClH","sourcesContent":[".hash-page {\n    width: 500px;\n    max-width: 500px;\n    margin: auto;\n    padding: 10px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);\n    color: #333;\n\n    min-height: 92vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.hash-page-main {\n    width: 99%;\n    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);\n    border-radius: 10px;\n    overflow-x: auto;\n}\n\n\n.hash-page_main_text{\n    color: #333; \n    box-shadow: 0px 0px 20px rgba(126, 2, 103, 0.2);\n    background-color: #f1f1f1;; \n    padding: 10px; \n    border-radius: 5px; \n    text-align: center; \n    width: 95%;\n}\n\n\n.base-button{\n    display: flex;\n    gap: 10px;\n    justify-content: center; \n    align-items: center;\n    margin-top: -20px;\n    padding-bottom: -20px;\n    /*box-shadow: 0px 0px 20px rgba(0,0,0,1);*/\n}\n\n.hash-page-unit{\n    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(126, 2, 103, 0.15), rgba(255, 255, 255, 1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
