import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { serverUrl } from '../Utils/storage';
import Question from './Question';
import QuestionLeft from './QuestionLeft';
import QuestionRight from './QuestionRight';
import ChartComponent from './ChartComponent';
import { generateColors } from './ChartComponent';

import './resultsSurveAdmin.css'
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

const SurveyResultsAdmin = () => {
  const { id } = useParams(); // Отримуємо id опитування з URL
  const {user_id } = useParams(); // Отримуємо id опитування з URL
  const [data, setData] = useState(null);
  const [similar, setSimilar] = useState(null);


  useEffect(() => {
    // Отримуємо дані результатів при завантаженні компонента
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${serverUrl}/survey/${id}/results/${user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching survey results:', error);
      }
    };

    const fetchSimilar = async () => {
        try {
          const response = await axios.get(`${serverUrl}/survey/${id}/all-results/${user_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Додаємо токен авторизації у заголовки
            },
          });
          setSimilar(response.data);
        } catch (error) {
          console.error('Error fetching survey results:', error);
        }
      };

      fetchSimilar();

      fetchResults();

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, [id]);

  if (!data || !similar) {
    return <div>Loading results...</div>;
  }

  return (
    <div style={{width: "100%"}}>
    <Container className='result-admin-survey-main'>
      <div className='result-admin-survey-top'>
        <h1>Poll Results</h1>
        <h2 style={{fontSize: '30px'}}>{data.survey.title}</h2>
        <p>{data.survey.description}</p>
      </div>

      <h3>Questions</h3>
      <div className='result-admin-survey-ser'>
      {data.questions.map((question, index) => {
        /*const responsesLeft = question.responses;*/
        let responsesLeft = [];
        question.responses.forEach((resp) => {
          responsesLeft.push(resp.value);
        });
        const responsesRight = similar[question.questionId];/*similar.questions[index].responses;*/
        const colors = generateColors(question.emotions);

        return (
          <div key={question.questionId} className="mb-4">
            <Question question={question} />
            <Row>
              <Col xs={6}>
                <QuestionLeft question={question} />
                <ChartComponent responses={responsesLeft} emotions={question.emotions} colors={colors}/>
              </Col>
              <Col xs={6}>
                <QuestionRight question={question} res_response={responsesRight} />
                <ChartComponent responses={responsesRight} emotions={question.emotions} colors={colors}/>
              </Col>
            </Row>
          </div>
        );
      })}
      </div>
    </Container>

      <TopPanelBase/> 
      <BotPanel is_fixed={false}/>
    </div>
  );
};
export default SurveyResultsAdmin;
