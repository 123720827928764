import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import './Question.css'
import { format } from 'date-fns';

const QuestionRight = ({ question, res_response, resp_length, resp_time }) => {
  // Get the latest responses directly from the question
  const latestResponses = res_response;/*question.response || [];*/

  // Ensure latestResponses is an array
  if (!Array.isArray(latestResponses)) {
    console.error('latestResponses is not an array:', latestResponses);
    return null;
  }
  return (
    <div>
      <h4>{resp_length} responses: {format(new Date(resp_time), 'yyyy-MM-dd HH:mm:ss')}</h4>
      {latestResponses.map((response, index) => (
        <Row key={response.response_id} className="mb-2 align-items-center">
          <Col xs={4}><h4 className="response-text">{question.emotions[index]}</h4></Col>
          <Col xs={8}>
            <ProgressBar 
              now={response} 
              label={`${response}%`} 
              className="small-progress" 
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default QuestionRight;

//      <h4>User {question.responses[0].user.name} has the most similar response</h4>