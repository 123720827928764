import React from 'react';
import { useLocation } from 'react-router-dom';
import { reactUrl } from '../Utils/storage';
import { useNavigate } from 'react-router-dom';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import './HashPage.css'
import '../mySurvey/mySurvey.css'
import copy_icon from '../icons/copy_icon.png'
import BotPanel from '../Components/BottomPanel/BotPanel'

function HashPage() {
  // Extract the hash from the URL
  const location = useLocation();
  const { hash } = location.state || {};
  let navigate = useNavigate();

  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
            alert('Link copied to clipboard: ' + text);
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    } else {
        try {
            let textarea = document.createElement("textarea");
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            let successful = document.execCommand('copy');
            if (successful) {
                alert('Link copied to clipboard: ' + text);
            } else {
                throw new Error('Copy command was unsuccessful');
            }
            document.body.removeChild(textarea);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
    }
  };

  return (

    <div className='base-unfixed-footer'>
    <div className='base-unfixed-footer-content'>

    <div className='hash-page'>
      <h1 className='hash-page_main_text'>Poll created successfully!</h1>

      <div className='hash-page-main'>

        <div className='hash-page-unit'>
          <p>Poll Code: {hash ? hash : 'No hash provided'}</p>
          <div className='base-button'>
            <button className='base-iconed-button' onClick={() => copyToClipboard(hash ? hash : '')}>
              Copy code<img src={copy_icon}  alt='copy-poll-hash' className="icon"/>
            </button>
          </div>
        </div>

        <div className='hash-page-unit'>
          <p>Poll Link: <a href={`${reactUrl}/survey/${hash}`}>{`${reactUrl}/survey/${hash}`}</a></p>
          <div className='base-button'>
            <button className='base-iconed-button' onClick={() => copyToClipboard(`${reactUrl}/survey/${hash}`)}>
              Copy link<img src={copy_icon}  alt='copy-poll-link' className="icon"/>
            </button>
          </div>
        </div>
        
        <div className='hash-page-unit'>
          <p>You can see your Polls here:</p>
          <div className='base-button'>
            <button onClick={() => navigate("/my-surveys")}>My Polls</button>
          </div>
        </div>


      </div>
    </div>
    </div>
    
    <TopPanelBase/>
    <BotPanel is_fixed={false}/>
    </div>
  );
}

export default HashPage;
