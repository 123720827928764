import React from 'react';
import './QuestionForm.css'; // Ensure your CSS styles this appropriately
import { emotions } from '../Utils/storage';


const QuestionForm = ({ question, setQuestion, index, handleRemoveQuestion }) => {


  const handleTypeChange = (e) => {
    const newType = e.target.value;
    // Clear previous answer when changing type
    const newAnswer = newType === 'text' ? '' : (newType === 'image' || newType === 'video') ? [] : question.answer;
    setQuestion({ ...question, type: newType, answer: newAnswer });
  };

  const toggleEmotion = (emotion) => {
    const newEmotions = question.emotions.includes(emotion)
      ? question.emotions.filter(e => e !== emotion)
      : [...question.emotions, emotion];
    setQuestion({ ...question, emotions: newEmotions });
  };

  const handleAnswerChange = (e) => {
    setQuestion({ ...question, answer: e.target.value });
  };

  

  const inputField = () => {
    switch (question.type) {
      case 'text':
        return <input type="text" placeholder="Enter text" value={question.answer || ''} onChange={handleAnswerChange} />;
      case 'image':
        return <input type="url" placeholder="Enter image URL" value={question.answer || ''} onChange={handleAnswerChange} />;
      case 'video':
        return <input type="url" placeholder="Enter video URL" value={question.answer || ''} onChange={handleAnswerChange} />;
      default:
        return <input type="text" placeholder="Enter text" value={question.answer || ''} onChange={handleAnswerChange} />;
    }
  };

  return (
    <div className="question-form">
      <select value={question.type} onChange={handleTypeChange}>
        <option value="text">Text</option>
        <option value="image">Image</option>
        <option value="video">Video</option>
      </select>
      {inputField()} 
      
      <button type="button" className="remove-question-button" onClick={() => handleRemoveQuestion(index)}>X</button>
      
      <div className="emotions-container-text">Choose emotions:</div>
      <div className="emotions-container">
        {emotions.map(emotion => (
          <button
            key={emotion}
            type="button"
            className={`emotion-button ${question.emotions.includes(emotion) ? 'selected' : ''}`}
            onClick={() => toggleEmotion(emotion)}
          >
            {emotion} {/* You can replace this with images or icons representing the emotions */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default QuestionForm;
