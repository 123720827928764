import setPictureFile from 'react';
import setPicturePreviewUrl from 'react';

export const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

export const validatePassword = (password) => {
    // Regular expression for password validation (at least 8 characters)
    return password.length >= 8;
  };


export const handlePictureChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPicturePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      setPictureFile(file);
    }
  };