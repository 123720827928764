// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/********************* BOT PANEL **********************/
.footer {
    background-color: #b115b7;
    color: #fff;
    padding: 5px;
    width: 100%;

    text-align: left;
    padding-left: 20px;
    position: relative;

    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
  }

.footer_fixed{
    background-color: #b115b7;
    color: #fff;
    padding: 5px;
    width: 100%;

    text-align: left;
    padding-left: 20px;

    bottom: 0;
    left: 0;
    position: fixed;

    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}
  /*******************************************/



.base-unfixed-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100vh;

}
.base-unfixed-footer-content{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/BottomPanel/BotPanel.css"],"names":[],"mappings":";AACA,uDAAuD;AACvD;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;;IAEX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;;IAElB,eAAe;IACf,4CAA4C;EAC9C;;AAEF;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;;IAEX,gBAAgB;IAChB,kBAAkB;;IAElB,SAAS;IACT,OAAO;IACP,eAAe;;IAEf,eAAe;IACf,4CAA4C;AAChD;EACE,4CAA4C;;;;AAI9C;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,gBAAgB;;AAEpB;AACA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n/********************* BOT PANEL **********************/\n.footer {\n    background-color: #b115b7;\n    color: #fff;\n    padding: 5px;\n    width: 100%;\n\n    text-align: left;\n    padding-left: 20px;\n    position: relative;\n\n    font-size: 18px;\n    font-family: 'Times New Roman', Times, serif;\n  }\n\n.footer_fixed{\n    background-color: #b115b7;\n    color: #fff;\n    padding: 5px;\n    width: 100%;\n\n    text-align: left;\n    padding-left: 20px;\n\n    bottom: 0;\n    left: 0;\n    position: fixed;\n\n    font-size: 18px;\n    font-family: 'Times New Roman', Times, serif;\n}\n  /*******************************************/\n\n\n\n.base-unfixed-footer{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    min-width: 100vh;\n\n}\n.base-unfixed-footer-content{\n    flex-grow: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
