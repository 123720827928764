import React, { useState } from 'react';
import './TopPanelBase.css'
import { useNavigate } from 'react-router-dom';


function TopPanelBase()
{
    const [language, setLanguage] = useState('eng'); // Додайте стейт для зберігання обраної мови
    let navigate = useNavigate();

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        //curr_language = lang;
        //alert("language changed!");
        // Тут можна додати логіку для зміни мови
    };
    const handleClick = () => {
      navigate('/'); // Adjust the path to your logged page
    };

    return(
        <div className="top_panel">
        <nav className="navbar-fixed" onClick={handleClick} style={{ cursor: 'pointer' }}>
          CollEmPoll
        </nav>
  
        <div className="language-selector">
      
          <select value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
            <option value="ukr">Українська</option>
            <option value="eng">English</option>
          </select>
        </div>
  
      </div>
    );
}


export default TopPanelBase;