import React from 'react';
import './surveyPage.css';

const Question = ({ question, emotions, handleEmotionChange }) => {
  // Function to render question content based on type
  const renderContent = (type, content) => {
    switch (type) {
      case 'text':
        return <p>{content}</p>;
      case 'image':
        return <img src={content} alt="question-related" className="question-image" />;
      case 'video':
        // Assuming content is a YouTube URL, extract the video ID
        const videoId = new URL(content).searchParams.get('v');
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return (
          <iframe
            title="video-question"
            width="560"
            height="315"
            src={embedUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      default:
        return null;
    }
  };

  return (
    <div className="survey-page-question-unit">
      {/* Render the content based on question type */}
      {renderContent(question.type, question.question)}

      {/* Render sliders for each emotion */}
      {question.emotions && Object.keys(emotions).map(emotion => (
        <div key={emotion} className='emotion-slider'>
          <label>{emotion}:</label>
          {emotions[emotion]}
          <input className='styled-slider'
            type="range"
            min="0"
            max="100"
            value={emotions[emotion]}
            onChange={(e) => handleEmotionChange(question.question_id, emotion, e.target.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default Question;
