import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './surveyPage.css';
import { serverUrl } from '../Utils/storage';
import axios from 'axios';
import Question from './Question';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'


function SurveyPage() {
  const { surveyHash } = useParams(); // This hook allows you to grab the surveyHash from the URL
  const [survey, setSurvey] = useState(null);
  const [emotions, setEmotions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for handling submission

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        // Fetch the survey data from your API
        const response = await axios.get(`${serverUrl}/survey/take/${surveyHash}`);
        if (response.status !== 200) {
          throw new Error('Survey not found');
        }
        const data = response.data;
        console.log(data);
        setSurvey(data);

        // Initialize emotions state here after data is fetched
        const initialEmotions = {};
        data.questions.forEach(question => {
          const questionEmotions = {};
          question.emotions.forEach(emotion => {
            questionEmotions[emotion] = 0; // Initialize slider value to 0
          });
          initialEmotions[question.question_id] = questionEmotions;
        });
        setEmotions(initialEmotions);
        console.log(initialEmotions);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurvey();
  }, [surveyHash]);

  function handleEmotionChange(questionId, emotion, value) {
    setEmotions(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [emotion]: value
      }
    }));
  }

  async function submitSurvey() {
    setIsSubmitting(true); // Set submitting state to true
    try {
      const authToken = localStorage.getItem('authToken');
  
      const response = await axios.post(
        `${serverUrl}/survey/submit`,
        {
          surveyHash,
          emotions
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
  
      if (response.data.success) {
        setSubmitted(true);
        navigate(`/survey-results/${survey.survey_id}`);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
      // Handle error
    } finally {
      setIsSubmitting(false); // Set submitting state to false
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!survey) {
    return <div>No survey data available.</div>;
  }

return (
  <div className='base-unfixed-footer'>
  <div className='base-unfixed-footer-content'>
  <div className='survey-page-main'>

    <div className='survey-page-top'>

      <h1>{survey.title}</h1>
      <p>{survey.description}</p>
    </div>

    <div className='survey-page-question-container'>
      {survey.questions.map((question) => (
        <Question
          key={question.question_id}
          question={question}
          emotions={emotions[question.question_id] || {}}
          handleEmotionChange={handleEmotionChange}
        />
      ))}
      </div>

      {!submitted && (
        <button onClick={submitSurvey} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit Survey'}
        </button>
      )}
      {submitted && <div>Survey submitted successfully!</div>}
      <p></p>

    </div>
    </div>
    <TopPanelBase/>
      <BotPanel is_fixed={false}/>
    </div>
  );
}

export default SurveyPage;
